// Path: ./src/utils/fireBaseRemoteConfig.ts
import { getApp, getApps, initializeApp } from '@firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from '@firebase/remote-config';
import { setRemoteConfig } from 'app/store/reducer';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuthApp = getAuth(firebaseApp);

/**
* This code utilizes Firebase Remote Config as a feature gatekeeper.
* By setting a value in the remote config, we can control whether a feature is available to users.
* For instance, if we add a new feature but don't want to release it publicly yet, we can set
the corresponding value in Firebase Remote Config. Once we are ready to release the feature,
we can update the value in Firebase, and users will then be able to access it.
*/
export const firebaseRemoteConfig = async (dispatch: any) => {
  /** Handles Firebase app initialization to avoid re-initialization of instances */
  try {
    let app;
    try {
      /** Attempt to retrieve an existing Firebase app instance
       * If no app exists, initialize a new one using the config
       */
      app = getApps().length ? getApp() : initializeApp(firebaseConfig);
    } catch (error) {
      /** Fallback initialization if getApps() fails
       * Creates a new Firebase app instance with provided config
       */
      app = initializeApp(firebaseConfig);
    }

    /** Initialize Remote Config with cache settings */
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 300000;

    /** This block of code implements a retry mechanism to fetch and activate remote configuration settings.
     * It attempts to retrieve the latest configuration from the remote server up to a maximum of `maxRetries` times.
     * The retry logic is essential for handling transient issues such as network instability or temporary server outages.
     * By retrying with exponential backoff delays, the application increases the likelihood of successfully obtaining
     * the necessary configuration without overwhelming the server with rapid repeated requests.
     * Successfully fetched configurations are dispatched to update the application's state, enabling dynamic feature toggling
     * based on remote settings. This approach enhances the application's reliability and user experience by ensuring
     * that critical configuration data is consistently retrieved and applied.
     */
    const maxRetries = 2;
    for (let i = 0; i < maxRetries; i++) {
      try {
        /** Fetch and activate remote config values */
        await fetchAndActivate(remoteConfig);
        const getSmallImagesCondition: any = getValue(
          remoteConfig,
          'getSmallImages',
        );
        const smallImagesEnabled = Boolean(
          getSmallImagesCondition._value === 'true' ||
            getSmallImagesCondition._value === true,
        );
        dispatch(
          setRemoteConfig({
            remoteConfig: {
              getSmallImagesCondition: smallImagesEnabled,
            },
          }),
        );
        return;
      } catch (error) {
        /** If not last retry, wait and try again */
        if (i === maxRetries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
  } catch (error) {
    console.error('firebaseRemoteConfig error:', error);
    /** Setting to false because it matches the default value in src/app/store/state.ts */
    dispatch(
      setRemoteConfig({
        remoteConfig: {
          getSmallImagesCondition: false,
        },
      }),
    );
  }
};
