import moment from 'moment';
/**
 * The reason why we need this is because when the in the back end open time and close time are number and we need to handle the decimal. So for example 8.75 it has to be. 8:45 PM.
 */
export function formatDecimalTime(decimalTime: number): string | undefined {
  try {
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);
    return moment({ hour: hours, minute: minutes }).format('h:mm A');
  } catch (error) {}
}
