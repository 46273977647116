// Path: ./src/app/components/CategoryList/index.tsx
/**
 *
 * CategoryList
 *
 */
import React, { memo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListHorizontalScroll } from '../ListHorizontalScroll';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useHistory, useLocation } from 'react-router-dom';
import { messages } from 'locales/messages';
import * as analytics from '../../../utils/analytics';
import { styled as stlyedMUI } from '@mui/material/styles';
import './style.css';
import { GoFlyerAppContext } from 'app/store/context';
import { getLocalizedName } from 'utils/getLocalizedName';

interface Props {
  showBanner?: boolean;
}

export const CategoryList: React.FunctionComponent<Props> = memo(
  ({ showBanner }: Props) => {
    const { t, i18n } = useTranslation();
    const { state } = React.useContext(GoFlyerAppContext);

    const staticTopCategoryData = [
      {
        id: 1,
        index: 1,
        text: '',
        testId: 'Favorites',
        pageRoute: 'favourites',
        icon: <FavoriteIcon />,
      },
      {
        id: 2,
        index: 2,
        pageRoute: 'flyers',
        text: t(messages.Explore()),
        testId: 'Explore',
      },
      {
        id: 3,
        index: 3,
        pageRoute: 'latest',
        text: t(messages.Latest()),
        testId: 'Latest',
      },
      {
        id: 4,
        index: 4,
        pageRoute: 'promotionlist',
        text: t(messages.Best_Deals()),
        testId: 'Best Deals',
      },
      {
        id: 5,
        index: 5,
        pageRoute: 'upcoming',
        text: t(messages.UpComing()),
        testId: 'UpComing',
      },
    ];

    const topCategoryData = [
      ...staticTopCategoryData,
      ...state.flyerTags.map((tag, index) => ({
        id: staticTopCategoryData.length + index + 1,
        pageRoute: `flyer/${tag.name.toLowerCase()}`,
        text: getLocalizedName(tag, i18n.language),
        testId: tag.name,
        icon: tag.image?.src ? (
          <img
            src={tag.image?.src}
            style={{ width: '24px', height: '24px', marginRight: '4px' }}
            alt={tag.name}
          />
        ) : undefined,
        index: tag.index,
      })),
    ].sort((a, b) => (a.index || 0) - (b.index || 0));

    const location = useLocation();
    let _id = 2;
    for (let index = 0; index < topCategoryData.length; index++) {
      const element = topCategoryData[index];
      if (element.pageRoute === location.pathname.split(`/`)[1]) {
        _id = element.id;
        break;
      }
    }
    const [selectedBtn, setSelectedBtn] = useState<number>(_id);
    const history = useHistory();
    async function clickButton(value) {
      setSelectedBtn(value.id);
    }
    const handleOnClick = useCallback(
      value =>
        history.push({
          pathname: `/${value.pageRoute}`,
        }),
      [history],
    );

    function addStyle(styleString) {
      const style = document.createElement('style');
      style.textContent = styleString;
      document.head.append(style);
    }

    const handlePerformSwapLeftRightOnce = () => {
      const result = sessionStorage.getItem('swapLeftRight');
      if (result !== 'done') {
        setTimeout(() => {
          addStyle(`
          .TopMenuList {
            position: relative;
            animation-name: topMenuListTab;
            animation-delay: 2s;
            animation-duration: 4s;
            animation-iteration-count: 1;
          }
          
          @keyframes topMenuListTab {
            0% {
              left: 0px;
              top: auto;
            }
            25% {
              left: -200px;
              top: auto;
            }
            50% {
              left: 0px;
              top: auto;
            }
          }
          `);
        }, 800);

        setTimeout(() => {
          addStyle(`
          .TopMenuList {
          }
          @keyframes topMenuListTab {
            
          }
          `);
          sessionStorage.setItem('swapLeftRight', 'done');
        }, 4800);
      }
    };

    useEffect(() => {
      handlePerformSwapLeftRightOnce();
    }, []);

    useEffect(() => {
      const found = topCategoryData.find(
        value => `/${value.pageRoute}` === location.pathname,
      );
      if (found) {
        setSelectedBtn(found.id);
      }
    }, [history.location]);

    useEffect(() => {
      const found = topCategoryData.find(
        value => `/${value.pageRoute}` === location.pathname,
      );
      let timer1: any = {};
      const result = sessionStorage.getItem('swapLeftRight');
      if (found && result === 'done') {
        /**
         * this is for when user comes back from details page of specific categroy so
         * it will show that categroy on top and scroll it into view
         */
        timer1 = setTimeout(() => {
          document.querySelector(`.category-${found.id}`)?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }, 50);
      }
      /**
       * this will clear Timeout
       * when component unmount like in willComponentUnmount
       */
      return () => {
        clearTimeout(timer1);
      };
    }, []);

    const CategoryList = topCategoryData?.map((value, key): any => (
      <CategoryItem
        data-testid={'category list ' + value.testId}
        key={`${key + value.text + value.id}`}
        onClick={() => {
          analytics.click_category_list(value.text);
          clickButton(value);
          handleOnClick(value);
          document.querySelector(`.category-${value.id}`)?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }}
        className={`category-${value.id}`}
        isSelected={value.id === selectedBtn}
      >
        {value?.icon && (React.isValidElement(value.icon) ? value.icon : <></>)}
        <span>{value.text}</span>
      </CategoryItem>
    ));
    return (
      <ListHorizontalScroll
        top={`calc(env(safe-area-inset-top) + ${
          showBanner ? '150px' : '59px'
        })`}
        bottom={'none'}
        children={CategoryList}
        className="TopMenuList"
      ></ListHorizontalScroll>
    );
  },
);

const CategoryItem = stlyedMUI('span')<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px',
    backgroundColor: 'white !important',
    height: '34px',
    minWidth: 'fit-content',
    paddingRight: '16px',
    paddingLeft: '16px',
    boxShadow: '0px 3px 12px #00000029',
    borderRadius: '10px',
    textAlign: 'center',
    font: 'normal normal medium 16px/34px SF Pro Text',
    letterSpacing: '0px',
    textTransform: 'capitalize',
    opacity: '1',
    marginBottom: '20px',
    color: isSelected ? '#007AFF' : '#707070',
    cursor: 'pointer',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  }),
);
