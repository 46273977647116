import { GfFlyerTag } from '@swagger/typescript-fetch-goflyer';

// Path: ./src/utils/FlyerTagListId.tsx
interface FlyerTagListProp {
  Grocery: {
    Id: string;
  };
  Restaurant: {
    Id: string;
  };
  ChineseSuperMarket: {
    Id: string;
  };

  ElectricalAppliances: {
    Id: string;
  };

  upComingFlyer: {
    Id: string;
  };
}

export const FlyerTagListId: FlyerTagListProp = {
  Grocery: {
    Id: '51a189af-5a46-4e51-b2cc-85584c3cfded',
  },
  Restaurant: {
    // Copy paste MenuTag id here
    Id: 'ae8a2c78-ac00-4ac6-8ba4-258da07c809c',
  },
  ChineseSuperMarket: {
    Id: '441ca9f7-b5e1-49a5-beda-604059dbb994',
  },
  ElectricalAppliances: {
    Id: '1b60cefb-5901-454e-ac84-2d1c879c16c7',
  },
  upComingFlyer: {
    Id: 'upComingFlyer',
  },
};

export const staticFlyerTagList = [
  {
    id: '51a189af-5a46-4e51-b2cc-85584c3cfded',
    name: 'Grocery',
    nameChinese: '所有超市',
    nameFrench: 'Épicerie',
    visible: true,
    index: 7,
    createdAt: new Date().toISOString() as unknown as Date,
    updatedAt: new Date().toISOString() as unknown as Date,
    isDeleted: false,
  },
  {
    id: 'ae8a2c78-ac00-4ac6-8ba4-258da07c809c',
    name: 'Restaurant',
    nameChinese: '餐厅',
    nameFrench: 'Restaurant',
    visible: true,
    index: 6,
    createdAt: new Date().toISOString() as unknown as Date,
    updatedAt: new Date().toISOString() as unknown as Date,
    isDeleted: false,
  },
  {
    id: '441ca9f7-b5e1-49a5-beda-604059dbb994',
    name: 'Asian Supermarket',
    nameChinese: '华人超市',
    nameFrench: 'Supermarché asiatique',
    visible: true,
    index: 3,
    createdAt: new Date().toISOString() as unknown as Date,
    updatedAt: new Date().toISOString() as unknown as Date,
    isDeleted: false,
  },
  {
    id: '1b60cefb-5901-454e-ac84-2d1c879c16c7',
    name: 'Electronics',
    nameChinese: '电器',
    nameFrench: 'Électronique',
    visible: true,
    index: 4,
    createdAt: new Date().toISOString() as unknown as Date,
    updatedAt: new Date().toISOString() as unknown as Date,
    isDeleted: false,
  },
] as GfFlyerTag[];
