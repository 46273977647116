import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import {
  GfFlyerItem,
  ShowAt,
} from '@swagger/typescript-fetch-goflyer/dist/api';
import { apiService } from 'utils/api';
import FlyerItemCard from 'app/mobile-desktop-common-components/FlyerItemCard';
import {
  EmptyStateContainer,
  ItemsGrid,
  LoadingContainer,
  NonPaginatedContainer,
} from './styled';

interface Props {
  storeIds: string[];
  limit: number;
}

const NonPaginatedItems: React.FC<Props> = ({ storeIds, limit }) => {
  const [items, setItems] = useState<GfFlyerItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response =
          await apiService.gfFlyerItemControllerFindAllByStoreIds(
            storeIds,
            ShowAt.Supermarket,
            1,
            limit,
          );
        setItems(response.results || []);
        setError(null);
      } catch (err) {
        setError('Failed to load flyer items. Please try again later.');
        console.error('Error fetching flyer items:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [storeIds, limit]);

  useEffect(() => {
    const updateIframeHeight = () => {
      try {
        window.parent.postMessage(
          { type: 'resize', height: containerRef.current?.scrollHeight || 0 },
          '*',
        );
      } catch (error) {
        console.error('Error updating iframe height:', error);
      }
    };
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'containerResize') {
        updateIframeHeight();
      }
    };

    window.addEventListener('message', handleMessage);
    const resizeObserver = new ResizeObserver(updateIframeHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    updateIframeHeight();

    return () => {
      window.removeEventListener('message', handleMessage);
      resizeObserver.disconnect();
    };
  }, []);

  const renderContent = () => {
    if (error) {
      return (
        <EmptyStateContainer>
          <Typography color="error" variant="h6">
            {error}
          </Typography>
        </EmptyStateContainer>
      );
    }

    if (loading) {
      return (
        <LoadingContainer>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading items...
          </Typography>
        </LoadingContainer>
      );
    }

    if (items.length === 0) {
      return (
        <EmptyStateContainer>
          <Typography variant="h6" color="text.secondary">
            No items found
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            There are no items available at the moment.
          </Typography>
        </EmptyStateContainer>
      );
    }

    return (
      <NonPaginatedContainer>
        <Box sx={{ p: 2 }}>
          <ItemsGrid>
            {items.map((item, index) => (
              <FlyerItemCard key={item.id || index} item={item} />
            ))}
          </ItemsGrid>
        </Box>
      </NonPaginatedContainer>
    );
  };

  return <div ref={containerRef}>{renderContent()}</div>;
};

export default NonPaginatedItems;
