// Path: ./src/app/pages/NewPromotionDetailPage/index.tsx
/**
 *
 * NewPromotionDetailPage
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { apiService } from '../../../utils/api';
import {
  GfPromotionDto,
  GfStoreDto,
  GfFlyerItemDto,
} from '@swagger/typescript-fetch-goflyer';
import { GetQuery } from 'utils/getQueryPara';
import ReactGA from 'react-ga';
import { Spinner } from 'app/components/Spinner';
import { GoFlyerAppContext } from 'app/store/context';
import {
  checkIfPromotionInFavList,
  checkIfFlyerItemInShoppingList,
  checkIfStoreInFavList,
  instanceOfPromotion,
} from 'utils/dtoUtility';
import { messages } from 'locales/messages';
import { setCustomer } from 'app/store/reducer';
import { NewPromotionDetailInfoComp } from 'app/components/NewPromotionDetailInfoComp';
import { NewPromotionDetailStoreComp } from 'app/components/NewPromotionDetailStoreComp';
import * as analytics from '../../../utils/analytics';
import { useAddRemoveShoppingListMobileFunc } from 'utils/commonFunctions/AddRemoveShoppingListMobile';
import CustomHelmet from 'app/components/Helmet';

export const NewPromotionDetailPage = memo(() => {
  const { t, i18n } = useTranslation();
  const promotionId = GetQuery('promotionId') as string;
  const flyerItemId = GetQuery('flyerItemId') as string;
  const [item, setItem] = React.useState<GfPromotionDto | GfFlyerItemDto>();
  const { state, dispatch } = React.useContext(GoFlyerAppContext);

  React.useEffect(() => {
    if (promotionId) {
      getPromotion();
    } else if (flyerItemId) {
      getFlyerItem();
    }
  }, []);

  const getPromotion = async () => {
    const response = await apiService.gfPromotionControllerFindOne(promotionId);
    analytics.view_deals_detail_page(response);
    setItem(response);
  };

  const getFlyerItem = async () => {
    const response = await apiService.gfFlyerItemControllerFindOne(flyerItemId);
    setItem(response);
  };

  const {
    clickRemoveFromShoppingListButton,
    addAndRemoveFlyerItemToShoppingList,
  } = useAddRemoveShoppingListMobileFunc();

  if (item === undefined) {
    return <Spinner></Spinner>;
  }

  const isPromotion = instanceOfPromotion(item);

  const promo_in_fav_list = isPromotion
    ? checkIfPromotionInFavList(
        item as GfPromotionDto,
        state.customer.gfShoppingList,
      )
    : checkIfFlyerItemInShoppingList(
        item as GfFlyerItemDto,
        state.customer.gfShoppingList,
      );

  const map_center = {
    lat: isPromotion
      ? (item as GfPromotionDto).placement.store.location.coordinates[1]
      : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.location
          .coordinates[1],
    lng: isPromotion
      ? (item as GfPromotionDto).placement.store.location.coordinates[0]
      : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.location
          .coordinates[0],
  };

  async function clickFavStoreButton(
    e,
    store: GfStoreDto,
    showLikeStoreButton,
  ) {
    ReactGA.event({
      category: '1.2',
      action: `Click Store ${store.merchant.name} fav store Button`,
    });
    e.preventDefault();
    let new_store_fav_list;
    if (showLikeStoreButton) {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerRemoveStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    } else {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerAddStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    }
    const customer = state.customer;
    customer.gfStoreFavouriteList = new_store_fav_list;
    dispatch(setCustomer(customer));
  }

  const store_in_fav_list = checkIfStoreInFavList(
    isPromotion
      ? (item as GfPromotionDto).placement.store
      : (item as GfFlyerItemDto).flyerItemList?.stores[0],
    state.customer.gfStoreFavouriteList,
  );

  return (
    <ProductDetailContainer id="NewPromotionDetailPage">
      <CustomHelmet
        title={`Goflyer - ${
          isPromotion ? item.placement.product.name : item.name
        }`}
        description={t(messages.homeDescription())}
        keywords={t(messages.homeKeywords())}
      />

      <NewPromotionDetailInfoComp
        promotionId={isPromotion ? (item as GfPromotionDto).id : undefined}
        flyerItemId={!isPromotion ? (item as GfFlyerItemDto).id : undefined}
        title={
          isPromotion
            ? (item as GfPromotionDto).promotionTag?.[0]?.name
            : (item as GfFlyerItemDto).name
        }
        promoinfavlist={promo_in_fav_list}
        productName={
          isPromotion
            ? (item as GfPromotionDto).placement.product.name
            : (item as GfFlyerItemDto).name
        }
        storeGfImages={
          isPromotion
            ? (item as GfPromotionDto).placement.product.gfImages
            : (item as GfFlyerItemDto).gfMultiImages?.map(val => val.gfImage)
        }
        merchantName={
          isPromotion
            ? (item as GfPromotionDto).placement.store.merchant.name
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.merchant.name
        }
        DiscountPrice={
          isPromotion
            ? (item as GfPromotionDto).price
            : (item as GfFlyerItemDto).salePrice
        }
        quantity={item.quantity}
        unit={item.unit}
        ActualPrice={
          isPromotion
            ? item?.placement?.price
              ? '$' + item.placement.price
              : undefined
            : (item as GfFlyerItemDto).regularPriceString
        }
        productDescription={
          isPromotion
            ? (item as GfPromotionDto).placement.product.description
            : (item as GfFlyerItemDto).priceRemarks
        }
        onClickAddToShoppingListButtonFnc={e =>
          isPromotion
            ? clickRemoveFromShoppingListButton(
                e,
                item,
                promo_in_fav_list !== undefined,
              )
            : addAndRemoveFlyerItemToShoppingList(
                e,
                item,
                promo_in_fav_list !== undefined,
              )
        }
      />

      <NewPromotionDetailStoreComp
        data-testid="Deals from same store"
        store={
          isPromotion
            ? (item as GfPromotionDto).placement.store
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]
        }
        merchantLogo={
          isPromotion
            ? (item as GfPromotionDto).placement.store.merchant.merchantLogo.src
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.merchant
                ?.merchantLogo?.src
        }
        merchantName={
          isPromotion
            ? (item as GfPromotionDto).placement.store.merchant.name
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.merchant?.name
        }
        merchantDescription={
          isPromotion
            ? (item as GfPromotionDto).placement.store.merchant.description
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.merchant
                ?.description
        }
        numberOfDealsText={` ${t(messages.View())} ${
          isPromotion
            ? (item as GfPromotionDto).placement.store.numberOfDeals
            : (item as GfFlyerItemDto).flyerItemList?.stores[0]?.numberOfDeals
        } ${t(messages.Deals_From_Same_Store())}`}
        mapCenter={map_center}
        storeInfavlist={store_in_fav_list}
        onClickFavStoreButtonFnc={e => {
          clickFavStoreButton(
            e,
            isPromotion
              ? (item as GfPromotionDto).placement.store
              : (item as GfFlyerItemDto).flyerItemList?.stores[0],
            store_in_fav_list !== undefined,
          );
        }}
      />
    </ProductDetailContainer>
  );
});

const ProductDetailContainer = styled.div`
  margin-top: 58px !important;
  margin: 10px;
  padding-bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
