export const getLocalizedName = (
  item: { name: string; nameChinese?: string; nameFrench?: string },
  language: string,
): string => {
  switch (language) {
    case 'zh':
      return item?.nameChinese || item?.name;
    case 'fr':
      return item?.nameFrench || item?.name;
    default:
      return item?.name;
  }
};
