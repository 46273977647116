// Path: ./src/app/components/SideNavBar/index.tsx
/**
 *
 * SideNavBar
 *
 */
import { messages } from 'locales/messages';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { GoFlyerLogo } from '../GoFlyerLogo/Loadable';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { SideNavMenuLink } from '../SideNavMenuLink/Loadable';
import DealIcon from '../../assets/deals.png';
import merchantLogo from '../../assets/flyer.png';
import ShoppingIcon from '../../assets/shoppingCart.svg';
import FeedBackIcon from '../../assets/feedback.svg';
import { GoFlyerAppContext } from 'app/store/context';
import { setMenuSideBar } from 'app/store/reducer';
import { getLocalizedName } from 'utils/getLocalizedName';

interface Props {
  totalTagsType: {
    grocery: number;
    restaurant: number;
    chinesesupermarket: number;
    electricalAppliances: number;
    totalFlyers?: number;
    upComing?: number;
    favCount?: number;
    [key: string]: number | undefined;
  };
}

export const SideNavBar = memo((props: Props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const handleSideMenu = () => {
    dispatch(setMenuSideBar(false));
  };
  const handleGetApp = () => {
    handleSideMenu();
    const userAgent: any =
      navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=io.ionic.goflyermobile';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        'https://apps.apple.com/ca/app/goflyer/id1580987749';
    }
  };
  return (
    <SideBar>
      <SideNav>
        <SideInnerView>
          <div>
            <SideTop className="sideTop">
              <LogoLink to={'/flyers'}>
                <GoFlyerLogo height="43px" />
              </LogoLink>
            </SideTop>
            <SideTopLinks>
              <SideNavMenuLink
                data-testid="Shopping List"
                active={location.pathname === '/shoppinglist'}
                to="/shoppinglist"
                onClick={handleSideMenu}
                icon={
                  <img src={ShoppingIcon} alt={ShoppingIcon} width="22px" />
                }
                text={t(messages.Shopping_List())}
                testId={'Shopping List'}
              />
              <SideNavMenuLink
                data-testid="Flyers"
                active={location.pathname === '/flyers'}
                to="/flyers"
                onClick={handleSideMenu}
                icon={<img src={merchantLogo} alt={DealIcon} width="22px" />}
                text={t(messages.Flyers())}
                testId={'Flyers'}
              />
              <SideNavMenuLink
                data-testid="Deals"
                active={location.pathname === '/promotionlist'}
                to="/promotionlist"
                onClick={handleSideMenu}
                icon={<img src={DealIcon} alt={DealIcon} width="22px" />}
                text={t(messages.Deals())}
                testId={'Deals'}
              />
              <SideNavMenuLink
                data-testid="Favourites"
                active={location.pathname === '/favourites'}
                to="/favourites"
                onClick={handleSideMenu}
                icon={<FavoriteIcon style={{ color: '#f58220' }} />}
                badge={props.totalTagsType.favCount}
                text={t(messages.Favourites())}
                testId={'Favourites'}
                justifyContent={'space-between'}
              />
            </SideTopLinks>
            <SideBottomLinks>
              <ButtonLink>
                <BottomLinkTitle data-testid="Flyers">
                  {t(messages.Flyers())}
                </BottomLinkTitle>
              </ButtonLink>
              <SideNavMenuLink
                data-testid="All Flyers"
                justifyContent={'space-between'}
                to="/flyers"
                onClick={handleSideMenu}
                active={location.pathname === '/flyers'}
                badge={props?.totalTagsType.totalFlyers}
                text={t(messages.All_Flyers())}
                testId={'All Flyers'}
              />
              {state.flyerTags?.map(tag => (
                <SideNavMenuLink
                  key={tag.id}
                  data-testid={tag.name}
                  justifyContent={'space-between'}
                  to={`/flyer/${tag.name.toLowerCase()}`}
                  onClick={handleSideMenu}
                  active={
                    location.pathname === `/flyer/${tag.name.toLowerCase()}`
                  }
                  badge={props?.totalTagsType[tag.name.toLowerCase()]}
                  text={getLocalizedName(tag, i18n.language)}
                  testId={tag.name}
                />
              ))}
              <SideNavMenuLink
                data-testid="Latest"
                justifyContent={'space-between'}
                to="/latest"
                onClick={handleSideMenu}
                active={location.pathname === '/latest'}
                badge={props.totalTagsType.totalFlyers}
                text={t(messages.Latest())}
                testId={'Latest'}
              />
              <SideNavMenuLink
                data-testid="Latest"
                justifyContent={'space-between'}
                to="/upcoming"
                onClick={handleSideMenu}
                active={location.pathname === '/upcoming'}
                badge={props.totalTagsType.upComing}
                text={t(messages.UpComing())}
                testId={'Up Coming'}
              />
            </SideBottomLinks>
          </div>
          <SideInfoLinks>
            <ButtonLink>
              <Link
                data-testid="Sign In"
                to={'/signin'}
                onClick={handleSideMenu}
              >
                <InfoLinkTitle data-testid="Sign In">
                  {state?.customer?.isLogin
                    ? t(messages.Profile())
                    : t(messages.Sign_In())}{' '}
                </InfoLinkTitle>
              </Link>
            </ButtonLink>
            <ButtonLink>
              <Link
                data-testid="Side Nav Bar About Link"
                to={'/about'}
                onClick={handleSideMenu}
              >
                <InfoLinkTitle data-testid="About Us">
                  {t(messages.About_Us())}{' '}
                </InfoLinkTitle>
              </Link>
            </ButtonLink>
            <ButtonLink>
              <InfoLinkTitle data-testid="Mobile App" onClick={handleGetApp}>
                {' '}
                {t(messages.Mobile_App())}
              </InfoLinkTitle>
            </ButtonLink>
            <ButtonLink>
              <InfoLinkTitle>
                <InfoTitleWithSub>
                  <span>
                    <a
                      data-testid="FAQ / Help"
                      href={
                        i18n.language === 'zh'
                          ? 'https://us18.list-manage.com/contact-form?u=7f4392a5936a835e1e1c0ea2c&form_id=ee7eeb04c707aabb133d4d47080d3562'
                          : 'https://us18.list-manage.com/contact-form?u=7f4392a5936a835e1e1c0ea2c&form_id=ee7eeb04c707aabb133d4d47080d3562'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {t(messages.FAQ_Help())}
                    </a>
                  </span>
                  <InfoLinkSub>
                    <a
                      data-testid="Give Some Feedback"
                      href={
                        i18n.language === 'zh'
                          ? 'https://us18.list-manage.com/contact-form?u=7f4392a5936a835e1e1c0ea2c&form_id=ee7eeb04c707aabb133d4d47080d3562'
                          : 'https://us18.list-manage.com/contact-form?u=7f4392a5936a835e1e1c0ea2c&form_id=ee7eeb04c707aabb133d4d47080d3562'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={FeedBackIcon} alt={FeedBackIcon} />
                      {t(messages.Give_Some_Feedback())}
                    </a>
                  </InfoLinkSub>
                </InfoTitleWithSub>
              </InfoLinkTitle>
            </ButtonLink>
            <ButtonLink style={{ marginTop: '15px' }}>
              <InfoLinkTitle data-testid="Terms of Use" href="/terms-of-use">
                {t(messages.Terms_of_Use())}
              </InfoLinkTitle>
            </ButtonLink>
            <ButtonLink>
              <InfoLinkTitle
                data-testid="Privacy Policy"
                href="/privacy-policy"
              >
                {' '}
                {t(messages.Privacy_Policy())}
              </InfoLinkTitle>
            </ButtonLink>
          </SideInfoLinks>
        </SideInnerView>
      </SideNav>
      <CloseDiv
        data-testid="side nav bar handle side menu close"
        onClick={handleSideMenu}
      ></CloseDiv>
    </SideBar>
  );
});

const CloseDiv = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: relative;
  background-color: #1f283366;
`;
const SideBar = styled.div`
  z-index: 999999;
  position: fixed;
  height: 100vh !important;
  width: 100vw;
  top: 0;
`;
const SideNav = styled.div`
  color: black;
  width: 210px;
  background: white 0% 0% no-repeat padding-box;
  height: 100vh !important;
  z-index: 999999;
  position: absolute;
  box-shadow: 0px -15px 31px #0000003b;
`;

const SideInnerView = styled.div`
  margin-right: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: scroll;
`;

const SideTop = styled.div`
  margin-top: env(safe-area-inset-top);
  height: 70px;
  border-bottom: 1px solid black;
  margin-left: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const SideTopLinks = styled.div`
  height: 120px;
  margin-top: 50px;
`;

const SideBottomLinks = styled.div`
  margin-top: 50px;
`;

const SideInfoLinks = styled.div``;

const BottomLinkTitle = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #6d6d6d;
  text-transform: capitalize;
  opacity: 1;
`;

const InfoLinkTitle = styled.a`
  text-align: left;
  letter-spacing: 0px;
  color: #6d6d6d;
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  height: 23px;
  text-decoration: none;
  outline: none;
`;

const InfoLinkSub = styled.span`
  margin-top: 2px;
  font-size: 10px;
`;
const LogoLink = styled(Link)`
background:  ${(props: { background?: boolean }) => {
  return props.background ? '#c5c5c54d 0% 0% no-repeat padding-box;' : '';
}}
  border-radius: 0px 8px 8px 0px;
  opacity: 1;
  padding: 5px;
  padding-left: 0px;
  margin: 4px 0px 4px 0px;
  padding-right: 0px;

`;

const InfoTitleWithSub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ButtonLink = styled.div`
background:  ${(props: { background?: boolean }) => {
  return props.background ? '#c5c5c54d 0% 0% no-repeat padding-box;' : '';
}}
  border-radius: 0px 8px 8px 0px;
  opacity: 1;
  padding: 5px;
  padding-left: 20px;
  margin: 4px 0px 4px 0px;
  padding-right: 0px;

`;
