import React from 'react';
import { GfImageDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { FlyerDetailViewV2 } from './FlyerDetailViewV2';
import { FlyerDetailViewV1 } from './FlyerDetailView';

interface FlyerDetailViewWrapperProps {
  handleShare: (params: any) => Promise<void>;
  store: GfStoreDto;
  gfImages: GfImageDto[];
  validStartDate?: Date;
  validEndDate?: Date;
  metaData?: string;
}

export const FlyerDetailViewWrapper: React.FC<
  FlyerDetailViewWrapperProps
> = props => {
  // Check if all gfImages have valid width and height
  const allImagesHaveDimensions = props.gfImages.every(
    img => img.width && img.height && img.width > 0 && img.height > 0,
  );

  return allImagesHaveDimensions ? (
    <FlyerDetailViewV2
      handleShare={props.handleShare}
      store={props.store}
      gfImages={props.gfImages}
      validStartDate={props.validStartDate}
      validEndDate={props.validEndDate}
      metaData={props.metaData}
    />
  ) : (
    <FlyerDetailViewV1
      handleShare={props.handleShare}
      store={props.store}
      gfImages={props.gfImages}
      validStartDate={props.validStartDate}
      validEndDate={props.validEndDate}
      metaData={props.metaData}
    />
  );
};
