import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { GfImageDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import * as analytics from '../../../utils/analytics';
import { TopDivView } from './TopDiv';

import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { GoFlyerAppContext } from 'app/store/context';
import CustomHelmet from '../Helmet';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../Spinner';
import { LoaderContainer } from './TopDivStyle';

interface FlyerDetailViewInterface {
  handleShare: (params: any) => Promise<void>;
  store: GfStoreDto;
  gfImages: GfImageDto[];
  validStartDate?: Date;
  validEndDate?: Date;
  metaData?: string;
}

export const FlyerDetailViewV2 = memo((props: FlyerDetailViewInterface) => {
  const { t } = useTranslation();

  const [iframeHeight, setIframeHeight] = useState(0);
  const [iframeWidth, setIframeWidth] = useState(0);
  const [showGrabbing, setShowGrabbing] = useState<boolean>(false);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const { state } = useContext(GoFlyerAppContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const previousPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const [loadedImages, setLoadedImages] = useState<number[]>([0, 1]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [imageLoaded, setImageLoaded] = useState<Record<number, boolean>>({
    0: false,
    1: false,
  });

  const setIframeHeightFunc = () => {
    let HeaderANavWrapperHeight = 0;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const headerWrapper = document.querySelector('.HeaderANavWrapper');

    if (headerWrapper) {
      HeaderANavWrapperHeight = headerWrapper.clientHeight;
    }
    setIframeHeight(windowHeight - HeaderANavWrapperHeight - 55);
    setIframeWidth(windowWidth);
    setIsLoading(false);
  };

  function getDistance(deltaPoint1, deltaPoint2) {
    return Math.sqrt(
      (deltaPoint1.x - deltaPoint2.x) ** 2 +
        (deltaPoint1.y - deltaPoint2.y) ** 2,
    );
  }

  function getCenter(deltaPoint1, deltaPoint2) {
    return {
      x: (deltaPoint1.x + deltaPoint2.x) / 2,
      y: (deltaPoint1.y + deltaPoint2.y) / 2,
    };
  }

  const customMoveAndZoomImage = elementOfImage => {
    let deltaLast: any = null;
    let lastDist: any = 0;
    let startContext: any = {};

    const handleTouchStart = event => {
      if (event?.touches?.length === 2) {
        event.preventDefault();
        startContext.twoTouchPanning = true;
      }
    };

    const handleTouchMove = event => {
      if (!transformComponentRef.current) return;
      if (event?.touches?.length === 2) {
        event.preventDefault();
        transformComponentRef.current.instance.clearPanning(event);

        let touch1 = event.touches[0];
        let touch2 = event.touches[1];

        let deltaPoint1 = { x: touch1.clientX, y: touch1.clientY };
        let deltaPoint2 = { x: touch2.clientX, y: touch2.clientY };

        if (!deltaLast) {
          deltaLast = getCenter(deltaPoint1, deltaPoint2);
          return;
        }

        let newDelta = getCenter(deltaPoint1, deltaPoint2);
        let dist = getDistance(deltaPoint1, deltaPoint2);

        if (!lastDist) lastDist = dist;

        let scale =
          (dist / lastDist) * transformComponentRef.current.state.scale;
        scale = Math.max(1, Math.min(scale, 10));

        let dx = (newDelta.x - deltaLast.x) * 1.5;
        let dy = (newDelta.y - deltaLast.y) * 1.5;

        let newPos = {
          x:
            newDelta.x -
            ((newDelta.x - transformComponentRef.current.state.positionX) /
              transformComponentRef.current.state.scale) *
              scale +
            dx,
          y:
            newDelta.y -
            ((newDelta.y - transformComponentRef.current.state.positionY) /
              transformComponentRef.current.state.scale) *
              scale +
            dy,
        };

        transformComponentRef.current.setTransform(
          newPos.x,
          newPos.y,
          scale,
          0.1,
          'linear',
        );
        lastDist = dist;
        deltaLast = newDelta;
      }
    };

    const handleTouchEnd = event => {
      lastDist = 0;
      deltaLast = null;

      if (!transformComponentRef.current || !startContext?.twoTouchPanning) {
        return;
      }

      event.preventDefault();
      transformComponentRef.current.instance.clearPanning(event);

      // if scale was above 6, scale down to 6
      if (transformComponentRef.current.state.scale > 6) {
        let scale = 6;
        let newPos = {
          x:
            (transformComponentRef.current.state.positionX /
              transformComponentRef.current.state.scale) *
            scale,
          y:
            (transformComponentRef.current.state.positionY /
              transformComponentRef.current.state.scale) *
            scale,
        };

        let imageRect: any =
          transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
        let containerRect: any =
          transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
        let correctedCords = { x: newPos.x, y: newPos.y };
        if (imageRect.left > containerRect.left)
          correctedCords.x -= imageRect.left - containerRect.left;
        if (imageRect.right < containerRect.right)
          correctedCords.x -= imageRect.right - containerRect.right;
        if (imageRect.top > containerRect.top)
          correctedCords.y -= imageRect.top - containerRect.top;
        if (imageRect.bottom < containerRect.bottom)
          correctedCords.y -= imageRect.bottom - containerRect.bottom;
        setTimeout(() => {
          transformComponentRef.current.setTransform(
            correctedCords.x,
            correctedCords.y,
            scale,
            200,
            'easeOut',
          );
        }, 100);
      } else {
        // calculating boundaries of image if it was out of scope
        let imageRect: any =
          transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
        let containerRect: any =
          transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
        let correctedCords = {
          x: transformComponentRef.current.state.positionX,
          y: transformComponentRef.current.state.positionY,
        };
        if (imageRect.left > containerRect.left)
          correctedCords.x -= imageRect.left - containerRect.left;
        if (imageRect.right < containerRect.right)
          correctedCords.x -= imageRect.right - containerRect.right;
        if (imageRect.top > containerRect.top)
          correctedCords.y -= imageRect.top - containerRect.top;
        if (imageRect.bottom < containerRect.bottom)
          correctedCords.y -= imageRect.bottom - containerRect.bottom;
        transformComponentRef.current.setTransform(
          correctedCords.x,
          correctedCords.y,
          transformComponentRef.current.state.scale,
          0.1,
          'linear',
        );
      }

      startContext.twoTouchPanning = false;
    };

    elementOfImage.addEventListener('touchstart', handleTouchStart);
    elementOfImage.addEventListener('touchmove', handleTouchMove);
    elementOfImage.addEventListener('touchend', handleTouchEnd);

    return () => {
      elementOfImage.removeEventListener('touchstart', handleTouchStart);
      elementOfImage.removeEventListener('touchmove', handleTouchMove);
      elementOfImage.removeEventListener('touchend', handleTouchEnd);
    };
  };

  useEffect(() => {
    setIframeHeightFunc();
    let touchTime = 0;

    const element = document.querySelector('.WrapperZoomPinch');
    if (!element) return;

    const handleClick = event => {
      if (!transformComponentRef.current) return;
      if (touchTime === 0) {
        touchTime = new Date().getTime();
      } else {
        if (new Date().getTime() - touchTime < 400) {
          analytics.double_click_flyer();
          if (transformComponentRef.current.state.scale < 2) {
            transformComponentRef.current.zoomIn(1);
          } else {
            transformComponentRef.current.zoomOut(
              Math.round(transformComponentRef.current.state.scale),
            );
          }
          touchTime = 0;
        } else {
          touchTime = new Date().getTime();
        }
      }
    };

    const zoomElement = document.querySelector('.react-transform-component');
    let cleanup = () => {};

    if (zoomElement) {
      cleanup = customMoveAndZoomImage(zoomElement);
    }

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
      cleanup?.();
    };
  }, [isLoading]);

  useEffect(() => {
    setIframeHeightFunc();
  }, [state.showBanner]);

  const calculateImageHeights = useCallback(
    (
      gfImages: GfImageDto[],
      iframeWidth: number,
    ): { averageHeight: number; totalHeight: number } => {
      if (!iframeWidth) return { averageHeight: 0, totalHeight: 0 };
      let totalHeight = 0;
      let count = 0;

      for (const img of gfImages) {
        if (img.width && img.height && img.width > 0 && img.height > 0) {
          const scaleFactor = iframeWidth / img.width;
          const scaledHeight = img.height * scaleFactor;
          totalHeight += scaledHeight;
          count++;
        }
      }

      const averageHeight = count > 0 ? totalHeight / count : 0;
      return { averageHeight, totalHeight };
    },
    [iframeWidth],
  );

  const { averageHeight, totalHeight } = calculateImageHeights(
    props.gfImages,
    iframeWidth,
  );

  const calculateIndex = (
    positionX: number,
    positionY: number,
    scale: number,
    imageHeightAverage: number,
  ): number => {
    if (!imageHeightAverage || imageHeightAverage <= 0) {
      return 0;
    }

    const imageHeightThreshold = imageHeightAverage * 0.8;
    const isVertical = true;

    let index = 0;
    if (isVertical) {
      const adjustedPositionY = positionY / scale;
      index = Math.floor(Math.abs(adjustedPositionY) / imageHeightThreshold);
    }

    return Math.min(Math.max(index, 0), props.gfImages.length - 1);
  };

  const handleImageLoad = (i: number) => {
    setImageLoaded(prev => ({ ...prev, [i]: true }));
  };

  const trackTransformChanges = useCallback(
    (
      ref: ReactZoomPanPinchRef,
      state: {
        scale: number;
        positionX: number;
        positionY: number;
      },
    ) => {
      if (ref) {
        const { positionX, positionY, scale } = ref.state;

        if (
          positionX !== previousPosition.current.x ||
          positionY !== previousPosition.current.y
        ) {
          previousPosition.current = { x: positionX, y: positionY };

          const newIndex = calculateIndex(
            positionX,
            positionY,
            scale,
            averageHeight,
          );
          setCurrentIndex(newIndex);
        }
      }
    },
    [previousPosition, averageHeight],
  );

  useEffect(() => {
    const nextImageIndex = currentIndex + 1;
    if (nextImageIndex < props.gfImages.length) {
      const missingIndices = Array.from(
        { length: nextImageIndex + 1 },
        (_, i) => i,
      ).filter(index => !loadedImages.includes(index));

      if (missingIndices.length > 0) {
        const sortedMissingIndices = missingIndices.sort((a, b) => a - b);
        setLoadedImages(prev => [...prev, ...sortedMissingIndices]);
        const newImageLoaded = sortedMissingIndices.reduce((acc, index) => {
          acc[index] = false;
          return acc;
        }, {});
        setImageLoaded(prev => ({ ...prev, ...newImageLoaded }));
      }
    }
  }, [currentIndex, loadedImages, props.gfImages.length]);

  if (props.gfImages === undefined || props.gfImages.length === 0) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }

  return (
    <div>
      <CustomHelmet
        title={`Goflyer - ${props.store.merchant.name}`}
        image={props.gfImages[0].src}
        description={t(messages.flyerViewIdDescription())}
        keywords={t(messages.flyerViewIdKeywords())}
      />
      {isLoading ? (
        <div>
          <Spinner></Spinner>
        </div>
      ) : (
        <div>
          <TopDivView
            validStartDate={props?.validStartDate}
            validEndDate={props?.validEndDate}
            handleShare={props.handleShare}
            store={props.store}
            marginTop={50}
          ></TopDivView>
          <div className="BottomDiv">
            <TransformWrapper
              limitToBounds={true}
              initialScale={1}
              wheel={{ wheelDisabled: true }}
              onPanningStart={() => {
                setShowGrabbing(true);
              }}
              doubleClick={{ disabled: true }}
              pinch={{ disabled: true }}
              onPinchingStop={e => {
                if (e.state.scale > 5) {
                  if (e.state.scale > 5.5) {
                    e.zoomOut(0.5);
                  } else {
                    e.zoomOut(0.2);
                  }
                }
              }}
              panning={{
                lockAxisX:
                  transformComponentRef?.current?.state?.scale > 1
                    ? false
                    : true,
              }}
              onPanningStop={() => {
                setShowGrabbing(false);
              }}
              onTransformed={trackTransformChanges}
              maxScale={6}
              ref={transformComponentRef}
            >
              {() => (
                <TransformComponent
                  wrapperStyle={{ height: iframeHeight }}
                  contentStyle={{ height: totalHeight }}
                  wrapperClass={`WrapperZoomPinch ${
                    showGrabbing ? 'Flyer-Image' : ''
                  }`}
                >
                  <div
                    style={{
                      width: iframeWidth,
                      position: 'relative',
                    }}
                    id="ZoomPinchImageElement"
                  >
                    {loadedImages.map(i => (
                      <div
                        key={props.gfImages[i]?.id}
                        style={{ position: 'relative' }}
                      >
                        {!imageLoaded[i] && (
                          <LoaderContainer>
                            <Spinner />
                          </LoaderContainer>
                        )}
                        <img
                          className="flyerImageTag"
                          style={{
                            width: '100%',
                            display: imageLoaded[i] ? 'block' : 'none',
                          }}
                          src={props.gfImages[i]?.src}
                          alt={props.gfImages[i]?.id}
                          onLoad={() => handleImageLoad(i)}
                        />
                      </div>
                    ))}
                  </div>
                </TransformComponent>
              )}
            </TransformWrapper>
          </div>
        </div>
      )}
    </div>
  );
});
