import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ScrollContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  height: '100vh',
  boxShadow: `
    inset 0 -10px 15px -7px rgba(0,0,0,0.15),
    inset 0 -5px 8px -3px rgba(0,0,0,0.08)
  `,
  borderRadius: '8px',

  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#555',
    },
  },
});

export const NonPaginatedContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  height: '100%',
  borderRadius: '8px',

  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#555',
    },
  },
});

export const LoadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4rem',
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: 10,
});

export const EmptyStateContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4rem',
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: 10,
});

export const ItemsGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
  columnGap: '10px',
  rowGap: '20px',
  width: '100%',
});
