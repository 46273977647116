export function encodeUrlIfNeeded(url: string): string {
  const unsafeCharacters = /[^A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]/;
  if (unsafeCharacters.test(url)) {
    return encodeURI(url);
  }
  return url;
}

export function normalizeTagName(uri: string): string {
  const isEncoded = uri !== decodeURIComponent(uri);
  const decodedURI = isEncoded ? decodeURIComponent(uri) : uri;
  const cleanedURI = decodedURI.replace(/\s+/g, '')?.toLowerCase();
  return cleanedURI;
}
