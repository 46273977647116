import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { GfFlyerTagState } from './types';
import { GfFlyerTag } from '@swagger/typescript-fetch-goflyer';
import { apiService } from 'utils/api';
import { staticFlyerTagList } from 'utils/FlyerTagListId';

export const initialState: GfFlyerTagState = {
  tags: [],
  loading: 'idle',
  error: null,
};

export const fetchGfFlyerTagsThunk = createAsyncThunk(
  'gfFlyerTags/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await apiService.getManyBaseGfFlyerTagControllerGfFlyerTag();
      if (!response?.data) {
        throw new Error('No data received');
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching tags:', error);
      return rejectWithValue(
        error instanceof Error ? error.message : 'Failed to fetch tags',
      );
    }
  },
);

const slice = createSlice({
  name: 'gfFlyerTag',
  initialState: initialState,
  reducers: {
    setFlyerTags(state, action: PayloadAction<GfFlyerTag[]>) {
      state.tags = action?.payload || [];
    },
    clearFlyerTags(state) {
      state.tags = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGfFlyerTagsThunk.pending, state => {
        state.loading = 'pending';
        state.error = null;
      })
      .addCase(fetchGfFlyerTagsThunk.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        const payloadTags = action.payload || [];
        const tagMap = new Map(staticFlyerTagList.map(tag => [tag.id, tag]));

        payloadTags.forEach(tag => tagMap.set(tag.id, tag));
        const data = Array.from(tagMap.values()).sort(
          (a, b) => (a.index || 0) - (b.index || 0),
        );
        state.tags = data;
        state.error = null;
      })
      .addCase(fetchGfFlyerTagsThunk.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { actions: gfFlyerTagActions } = slice;

export const useGfFlyerTagSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

export default slice.reducer;
