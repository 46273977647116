// Path: ./src/app/desktop-src/store/FlyerList/index.ts
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { FlyerListData, FlyerListStateProps } from './types';

const defaultFlyerListData: FlyerListData = {
  count: 0,
  currentPage: 1,
  itemsPerPage: 1,
  results: [],
  scrollPosition: 0,
};

export const initialState: FlyerListStateProps = {
  All: { ...defaultFlyerListData },
  Latest: { ...defaultFlyerListData },
  upComing: { ...defaultFlyerListData },
  searchPage: {
    count: 0,
    currentPage: 1,
    itemsPerPage: 1,
    scrollPosition: 0,
    resultsFlyer: [],
    resultsPromotion: [],
    resultsFlyerItem: [],
  },
};

const slice = createSlice({
  name: 'flyerList',
  initialState,
  reducers: {
    addFlyerList(
      state,
      action: PayloadAction<{
        type: string;
        condition?: string;
        data: any;
      }>,
    ) {
      const { type, condition, data } = action.payload;

      if (!state[type]) {
        state[type] = { ...defaultFlyerListData };
      }

      if (condition === 'PageNumber') {
        state[type] = {
          ...state[type],
          currentPage: data,
        };
      } else if (condition === 'scrollPosition') {
        state[type] = {
          ...state[type],
          scrollPosition: data,
        };
      } else {
        state[type] = {
          ...state[type],
          ...data,
        };
      }
    },
  },
});

export const { actions: customerActions } = slice;
export const UserReducer = slice.reducer;
export const useFlyerListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });

  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFlyerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
