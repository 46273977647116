// Path: ./src/utils/api.ts
/**
 * This service implements functionality to make api calls through open api generated client
 * We are adding custom axios instance which helps customize the generated client with interceptors and more axios functionalities
 */
import {
  DefaultApi,
  GfImageDto,
  GfMultiImage,
} from '@swagger/typescript-fetch-goflyer';
import { DecorateAll } from 'decorate-all';
// Configuration and base path are not provided
import i18next from 'i18next';
import deepForEach from 'deep-for-each';
import {
  instanceOfFlyerItem,
  instanceOfFlyerTag,
  instanceOfMerchant,
  instanceOfPromotion,
} from './dtoUtility';

function replaceS3SrcWithCloudFrontSrc(value: any, subject: any) {
  /**
   * Will migrate to backend later.
   */
  if (
    value &&
    value.src &&
    value.src.indexOf('s3') >= 0 &&
    subject.length > 0
  ) {
    const _subject = subject as GfImageDto[];
    for (let index = 0; index < _subject.length; index++) {
      const element = _subject[index];
      if (
        element &&
        element.src.indexOf(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.src = element.src.replace(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_PROD,
        );
      }
      if (
        element &&
        element.srcSmall &&
        element.srcSmall.indexOf(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.srcSmall = element.srcSmall.replace(
          'https://goflyer-app-image.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_PROD,
        );
      }
      if (
        element &&
        element.src.indexOf(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.src = element.src.replace(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_TEST,
        );
      }
      if (
        element &&
        element.srcSmall &&
        element.srcSmall.indexOf(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
        ) >= 0
      ) {
        element.srcSmall = element.srcSmall.replace(
          'https://goflyer-app-image-test.s3.ca-central-1.amazonaws.com',
          window.config.REACT_APP_CLOUD_FRONT_TEST,
        );
      }
    }
  }
}

function replaceSrcWithSrcSmallForMerchantLogos(value: any, subject: any) {
  if (value && subject) {
    if (instanceOfMerchant(subject)) {
      if (subject.merchantLogo) {
        subject.merchantLogo.src =
          subject.merchantLogo.srcSmall || subject.merchantLogo.src;
      }
      /**
       * Issue Analysis: Logo Dimension Problem
       *
       * The problem stems from logo dimension mismatching where:
       * - Small logo dimensions: 465x456 pixels
       * - Original logo dimensions: 100x100 pixels
       *
       * When replacing the logo source with smallSrc:
       * - The resized logo significantly exceeds original dimensions
       * - Instead of maintaining 100x100, it expands to 400x400
       * - This size discrepancy adversely affects the map rendering
       *
       * Current behavior:
       * - Logo resizing is not maintaining expected dimensions
       * - Resulting in oversized logos that disrupt layout
       *
       * @note Further investigation needed for resize logic. I am disabling this logic for now
       */
      // if (subject.merchantMapSmallLogo) {
      //   subject.merchantMapSmallLogo.src =
      //     subject.merchantMapSmallLogo.srcSmall ||
      //     subject.merchantMapSmallLogo.src;
      // }
      // if (subject.merchantMapLargeLogo) {
      //   subject.merchantMapLargeLogo.src =
      //     subject.merchantMapLargeLogo.srcSmall ||
      //     subject.merchantMapLargeLogo.src;
      // }
    }

    if (instanceOfPromotion(subject)) {
      if (subject.placement.product) {
        subject.placement.product.gfImages =
          subject.placement.product.gfImages?.map(val => {
            return { ...val, src: val.srcSmall || val.src };
          });
      }
    }

    if (instanceOfFlyerItem(subject)) {
      if (subject?.gfMultiImages?.length) {
        subject.gfMultiImages = subject.gfMultiImages?.map(val => {
          return {
            ...val,
            gfImage: {
              ...val.gfImage,
              src: val.gfImage.srcSmall || val.gfImage.src,
            },
          };
        });
      } else if (subject.gfImages?.length) {
        subject.gfMultiImages = subject.gfImages?.map(obj => {
          const { id, isDeleted, gfFlyerItems, gfMultiImage, ...val } = obj;
          return {
            id,
            isDeleted,
            gfFlyerItem: { id: subject.id },
            gfImage: {
              ...val,
              src: val.srcSmall || val.src,
            },
          };
        }) as GfMultiImage[];
      }
    }
  }
}

const Uppercase = (
  target: any,
  propertyKey: any,
  descriptor?: any | undefined,
) => {
  if (descriptor) {
    const original = descriptor.value;
    descriptor.value = async function (...args) {
      try {
        const result = await original.apply(this, args);
        deepForEach(result, (value, key, subject) => {
          if (
            i18next.language === 'zh' &&
            key === 'nameChinese' &&
            !instanceOfFlyerTag(subject)
          ) {
            subject.name =
              subject.nameChinese?.length > 0
                ? subject.nameChinese
                : subject.name;
          }
          if (instanceOfFlyerItem(subject)) {
            if (i18next.language === 'zh' && subject.limitStringChinese) {
              subject.limitString =
                subject.limitStringChinese || subject?.limitString;
            } else if (i18next.language === 'fr' && subject.limitStringFrench) {
              subject.limitString =
                subject.limitStringFrench || subject?.limitString;
            }
          }
          replaceS3SrcWithCloudFrontSrc(value, subject);
          replaceSrcWithSrcSmallForMerchantLogos(value, subject);
        });
        return result;
      } catch (error: any) {
        console.error(error);
        if (
          error &&
          error.toString().indexOf('Unexpected end of JSON input') >= 0
        ) {
          /**
           * don't use this window.location.href to redirect to 404.
           * it is because when window.location.href, it will reload the page. If error happen during reload, it will come here again and it will keep reloading
           */
          // window.location.href = '/404';
        }
      }
    };
  }
};

@DecorateAll(Uppercase, { deep: true })
class Hello extends DefaultApi {}

let config_response;
const apiService = new Hello(
  undefined,
  window.config.REACT_APP_GOFLYER_API_END_POINT,
);
export { apiService, config_response };
